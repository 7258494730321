import React, {useEffect, useMemo} from "react";
import {ReactComponent as TranscriptionIcon} from '../../assets/transcription.svg';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import CustomizedIconButton from "../IconButton/IconButton";
import {actions} from "../../store";
import {ReduxState} from "../../store/types";
import {showErrorToast} from "../../utils/showErrorToast";


const Transcription: React.VFC<{ callRecordingId?: string, refresh: boolean }> = ({callRecordingId, refresh}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation()

    const isTranscriptionAvailable = useSelector<ReduxState, boolean>(
        (state) => state.calls.isTranscriptionAvailable,
    );

    const isTranscriptionDownloading = useSelector<ReduxState, boolean>(
        (state) => state.calls.isTranscriptionDownloading,
    );

    const isDisabled = useMemo(() => {
        return !isTranscriptionAvailable || isTranscriptionDownloading
    }, [isTranscriptionAvailable, isTranscriptionDownloading])


    const handleDownloadFile = () => {
        if(callRecordingId)
        {
             showErrorToast(t<string>('common:downloadWillStartShortly'));
             dispatch(actions.downloadTranscriptionFile.request({callRecordingId: callRecordingId}))
        }

    }

    useEffect(() => {

        if (refresh || callRecordingId) {
            callRecordingId && dispatch(actions.checkIfTranscriptionExist.request({callRecordingId: callRecordingId}))
        }

    }, [refresh, callRecordingId]);

    return (
        <>
            <CustomizedIconButton
                onClick={handleDownloadFile}
                tooltipText={isDisabled && !isTranscriptionDownloading ? t('tooltips:calls.disabledDownloadTranscription') : t('tooltips:calls.downloadTranscription')}
                disabled={isDisabled}
                dataTestId={'button-download-transcription'}
                skipPermission
                above
            >
                <TranscriptionIcon/>

            </CustomizedIconButton>
        </>

    )
}

export default Transcription